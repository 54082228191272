import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { PAGE_TYPE } from '../../../constants';

class TrafficTrack extends React.Component {
  componentDidUpdate(prevProps) {
    this.sendCharbeatAnalytics(prevProps);
  }

  sendCharbeatAnalytics = prevProps => {
    if (this.props.qt.currentPath !== prevProps.qt.currentPath) {
      let sections = '';
      let authors = '';
      switch (this.props.qt.pageType) {
        case PAGE_TYPE.SECTION_PAGE:
          sections = get(this.props.qt, ['data', 'section', 'name'], '');
          break;
        case PAGE_TYPE.STORY_PAGE:
          sections = get(this.props.qt, ['data', 'story', 'sections'], [])
            .map(section => section.name)
            .join(', ');
          authors = get(this.props.qt, ['data', 'story', 'authors'], [])
            .map(section => section.name)
            .join(', ');
          break;
      }
      if (window && window.pSUPERFLY && window.pSUPERFLY.virtualPage) {
        window.pSUPERFLY.virtualPage({
          sections: sections,
          authors: authors,
          path: this.props.qt.currentPath,
          title: get(this.props.qt, ['data', 'title'], '')
        });
      }
    }
  };

  render() {
    return null;
  }
}

TrafficTrack.propTypes = {
  qt: PropTypes.object
};

const mapStateToProps = state => ({
  qt: state.qt
});

export default connect(
  mapStateToProps,
  {}
)(TrafficTrack);
