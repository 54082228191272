import React from 'react';
import { string, node, bool } from 'prop-types';
import './sub-menu-bar-item.m.css';

const SubMenuBarItem = ({ href, active, ...props }) => {
  return (
    <a href={href} styleName={`sub-menu-item ${active ? 'active' : ''}`}>
      {props.children}
    </a>
  );
};

SubMenuBarItem.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
  active: bool
};

SubMenuBarItem.defaultProps = {
  active: false
};

export default SubMenuBarItem;
