/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import { get } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { BQLink } from '../../atoms/bqlink';
import './sponsor-strip.m.css';

const SponsorStrip = ({ sponsors }) => {
  const sponsorsLogo = get(sponsors, ['sponsors-logo'], []);
  return (
    <div
      className="container"
      styleName="davosBand"
      style={{ border: '1px solid var(--font1-color)' }}
    >
      <img
        src={get(sponsors, ['davos-logo', 'image'], '')}
        height={get(sponsors, ['davos-logo', 'height'], '')}
        width={get(sponsors, ['davos-logo', 'width'], '')}
        alt={get(sponsors, ['davos-logo', 'altText'], '')}
        className="desktop-only"
      />
      <img
        src={get(sponsors, ['davos-logo', 'mobile', 'image'], '')}
        height={get(sponsors, ['davos-logo', 'mobile', 'height'], '')}
        width={get(sponsors, ['davos-logo', 'mobile', 'width'], '')}
        alt={get(sponsors, ['davos-logo', 'altText'], '')}
        className="mobile-only"
      />
      <div styleName="davos-sponsors" className="desktop-only">
        {sponsorsLogo &&
          sponsorsLogo?.map((sponsor, index) => (
            <img
              key={index}
              styleName="sponsor-logo"
              src={sponsor?.image}
              width={sponsor?.width}
              height={sponsor?.height}
              alt={sponsor?.altText}
            />
          ))}
      </div>
      <div styleName="davos-sponsors" className="mobile-only">
        <div styleName="mobile-sponsors">
          {sponsorsLogo &&
            sponsorsLogo?.map((sponsor, index) => (
              <img
                key={index}
                styleName="sponsor-logo"
                src={sponsor?.image}
                width={sponsor?.mobile?.width}
                height={sponsor?.mobile?.height}
                alt={sponsor?.altText}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  sponsors: get(state, ['qt', 'config', 'publisher', 'davos_sponsor_band'], [])
});

export default connect(mapStateToProps)(SponsorStrip);
