import React from 'react';
import { string } from 'prop-types';

const Loader = ({ color = '#fff', width = '46' }) => {
  return (
    <svg
      data-testid="loader"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      viewBox="-14 15 100 57"
    >
      <circle fill={color} cx="6" cy="40" r="10">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"
        />
      </circle>
      <circle fill={color} cx="36" cy="40" r="10">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.2"
        />
      </circle>
      <circle fill={color} cx="66" cy="40" r="10">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.3"
        />
      </circle>
    </svg>
  );
};

Loader.propTypes = {
  color: string,
  width: string
};

export default Loader;
