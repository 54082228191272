import React from 'react';
import { string } from 'prop-types';

const SubscriptionIcon = ({ color = 'border6-color', fontSize = '19px', className = '' }) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_receipt ${className}`}
  />
);

SubscriptionIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default SubscriptionIcon;
