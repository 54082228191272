import React from 'react';
import { string, object } from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

if (require.context) {
  const req = require.context('../../../../assets/icons', true, /\.svg$/);
  req.keys().forEach(filename => req(filename));
}

const SvgIcon = ({
  type,
  className = '',
  iconStyle = {},
  width = '16',
  height = '16',
  viewBox = '0 0 16 16',
  source,
  isSvg = true,
  loading
}) => {
  const svgSpritePath = useSelector(state => get(state, ['qt', 'config', 'svgSpritePath'], ''));

  return isSvg ? source === 'image' ? (
    <img style={iconStyle} src={`/icons/${type}.svg`} alt="NDTV Profit" width={width} height={height} loading={loading} />
  ) : (
    <svg className={className} style={iconStyle} width={width} height={height} viewBox={viewBox}>
      <use href={`${svgSpritePath}#${String(type).toLowerCase()}`} />
    </svg>
  ) : type.endsWith('.jpg') || type.endsWith('.png') || type.endsWith('.webp') ? (
    <img src={`/icons/${type}`} width={width} height={height} alt="NDTV Profit" loading={loading} />
  ) : (
    <img src={`/icons/${type}.png`} width={width} height={height} alt="NDTV Profit" loading={loading} />
  );
};

SvgIcon.propTypes = {
  type: string,
  className: string,
  iconStyle: object,
  width: string,
  height: string,
  viewBox: string,
  source: string,
  loading: string
};

export default SvgIcon;
