import React from 'react';
import { string } from 'prop-types';

const CrossBtn = ({ color = 'font1-color', fontSize = 'var(--base-smallest)', className = '' }) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_close ${className}`}
  />
);

CrossBtn.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default CrossBtn;
