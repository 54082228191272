import React from 'react';
import PropTypes from 'prop-types';
import './popup.module.css';

class Popup extends React.Component {
  constructor(props) {
    super(props);
    this.closePopup = this.closePopup.bind(this);
    this.escFunction = this.escFunction.bind(this);
    this.freezeBodyScroll = this.freezeBodyScroll.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.closePopup();
    }
  }

  closePopup() {
    this.props.onClose();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    this.freezeBodyScroll();
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
    const element = document.getElementsByTagName('body')[0];
    element.classList.remove('overflowHidden');
  }

  componentDidUpdate() {
    this.freezeBodyScroll();
  }

  freezeBodyScroll() {
    if (this.props.isAttachmentPreview) return;
    const element = document.getElementsByTagName('body')[0];
    if (this.props.isOpen) {
      element.classList.add('overflowHidden');
    } else {
      element.classList.remove('overflowHidden');
    }
  }

  render() {
    if (!this.props.isOpen) return null;
    return (
      <div styleName={`container theme-petrol-blue`}>
        <div styleName="wrapper">
          {!this.props.hideCloseButton ? (
            <div styleName="head">
              <h4 styleName="title">{this.props.title}</h4>
              {this.props.noCloseBtn || <button styleName="close" onClick={this.closePopup} />}
            </div>
          ) : null}
          <div styleName={this.props.isAttachmentPreview ? 'bodya4size' : 'body'}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  children: PropTypes.element.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  theme: PropTypes.string,
  noCloseBtn: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  isAttachmentPreview: PropTypes.bool
};

Popup.defaultProps = {
  isOpen: false,
  onClose: () => {},
  title: '',
  theme: '',
  isAttachmentPreview: false
};

export default Popup;
