import React from 'react';
import { func, string } from 'prop-types';

const SearchIcon = ({
  color = 'font1-color',
  fontSize = 'var(--base-medium)',
  className = '',
  onClick
}) => (
  <span
    onClick={onClick}
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_search ${className}`}
  />
);

SearchIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string,
  onClick: func
};

export default SearchIcon;
