import React from 'react';
import { string } from 'prop-types';

const NightIcon = ({
  color = 'border6-color',
  fontSize = 'var(--base-medium)',
  className = ''
}) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_crescent_moon ${className}`}
  />
);

NightIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default NightIcon;
