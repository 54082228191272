import React from 'react';
import { string } from 'prop-types';

const LogoutIcon = ({ color = 'bg6-color', fontSize = '14px', className = '' }) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_power ${className}`}
  />
);

LogoutIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default LogoutIcon;
