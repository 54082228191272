import React from 'react';
import { string } from 'prop-types';

const NotificationsIcon = ({
  color = 'font1-color',
  fontSize = 'var(--base-large)',
  className = ''
}) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize,
      position: 'relative'
    }}
    className={`icon-ic_notifications ${className}`}
  >
  </span>
);

NotificationsIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default NotificationsIcon;
