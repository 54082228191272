import React from 'react';
import { array, string, object, bool } from 'prop-types';
import Accordion from '../../atoms/accordion';
import './link-list.m.css';

const LinkList = ({
  links,
  sectionHeading,
  gridType = 'col',
  styling,
  sectionDivider = 'default',
  accordion = false,
  className
}) =>
  links ? (
    <Accordion disable={!accordion} className={className}>
      <div styleName="list">
        {sectionHeading && <h6 styleName="heading">{sectionHeading}</h6>}
        <div styleName={gridType === 'col' ? 'items vertical' : 'items horizontal'}>
          {links.map((i, index) => {
            return (
              <React.Fragment key={`${i.href}-${index}`}>
                <a
                  styleName="item"
                  href={i.href}
                  target={i.target}
                  rel={i.rel}
                  style={styling && styling.listItem}
                >
                  {i.name}
                </a>
                {sectionDivider === 'dot' && index !== links.length - 1 && <div styleName="dot" />}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </Accordion>
  ) : null;

LinkList.propTypes = {
  links: array,
  sectionHeading: string,
  gridType: string,
  sectionDivider: string,
  styling: object,
  accordion: bool,
  className: string
};

export default LinkList;
