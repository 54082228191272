import React from 'react';
import { any, object, string } from 'prop-types';
import { Link } from '@quintype/components';

export const BQLink = ({ story, href, children, target, ...props }) => {
  let hrefValue = `/${href}`;
  if (
    typeof href === 'string' &&
    (href?.startsWith('/') ||
      href?.includes('mailto:') ||
      href?.includes('www.') ||
      href?.includes('https://'))
  ) {
    hrefValue = href;
  }
  if (story?.['story-template'] === 'news-elsewhere') {
    hrefValue = story.metadata['reference-url'];

    if (hrefValue.includes('ndtv.com')) {
      hrefValue += '?utm_source=ndtvprofit&utm_medium=ndtvprofit&utm_campaign=ndtvprofit';
    }

    target = '_blank';
  }

  return (
    <Link href={hrefValue} {...props} disableAjaxLinks target={target}>
      {children}
    </Link>
  );
};

BQLink.defaultProps = {
  href: '',
  target: '_self',
};

BQLink.propTypes = {
  children: any,
  href: string,
  story: object,
  target: string,
};
