import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './offline-notification.m.css';
class OfflineNotification extends React.Component {
  constructor() {
    super();
    this.state = {
      isOffline: !navigator.onLine,
      popup: !navigator.onLine
    };
    this.handleNetworkChange = this.handleNetworkChange.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
  }

  componentDidMount() {
    global.addEventListener('online', this.handleNetworkChange);
    global.addEventListener('offline', this.handleNetworkChange);
  }

  getSnapshotBeforeUpdate(prevProps) {
    return {
      popup:
        prevProps.currentPath !== this.props.currentPath &&
        this.state.popup &&
        !this.state.isOffline
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.popup) this.closePopup();
  }

  handleNetworkChange() {
    this.setState({ isOffline: !navigator.onLine, popup: true });
  }

  closePopup() {
    this.setState({ popup: false });
  }

  componentWillUnmount() {
    global.document.removeEventListener('online', this.handleNetworkChange);
    global.document.removeEventListener('offline', this.handleNetworkChange);
  }

  reloadPage(e) {
    e.preventDefault();
    global.location.reload();
  }

  render() {
    return this.state.popup ? (
      <div styleName="notification-container">
        <div styleName={`notification-wrapper ${this.state.isOffline ? 'offline-wrapper' : ''}`}>
          <h2 styleName="notification-text">
            {this.state.isOffline ? (
              <div styleName="offline">
                <span>
                  <span className="icon-ic_offline"></span>Your Internet is offline
                </span>
              </div>
            ) : (
              <div styleName="online">
                <span>
                  <span className="icon-ic_online"></span> Your Internet is back online
                </span>
                <a href="#" onClick={this.reloadPage} styleName="notification-reload-button">
                  <img
                    styleName="online-image"
                    src="/images/ic_relaod-small.png"
                    width="12px"
                    height="15px"
                    alt="refresh"
                  />
                  REFRESH
                </a>
              </div>
            )}
          </h2>
        </div>
      </div>
    ) : null;
  }
}

OfflineNotification.propTypes = {
  currentPath: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  currentPath: state.qt.currentPath || ''
});

export default connect(mapStateToProps, {})(OfflineNotification);
