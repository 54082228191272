import React from 'react';
import { string } from 'prop-types';

const AfternoonIcon = ({
  color = 'border6-color',
  fontSize = 'var(--base-large)',
  className = ''
}) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_sun ${className}`}
  />
);

AfternoonIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default AfternoonIcon;
