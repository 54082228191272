/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import DropdownMenu from './dropdown-menu';
import LoggedInView from './logged-in-view';
import LoggedOutView from './logged-out-view';
import ContactUs from '../../molecules/contact-us/contact-us';
import { clearSSOUserDetails, verifySSODetails } from '../../../helpers/utils';
import {
  getSSOLogoutUrl,
  getSSORedirectUrl,
} from '../../../helpers/subscription-helper';

import './member-menu.module.css';

const MemberMenuView = ({ ...props }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => get(state, ['userData', 'isLoggedIn'], null));
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showBtnLoader, setShowBtnLoader] = useState(true);
  const [isContactUsOpen, setIsContactUsOpen] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');
  const SSO_LOGOUT_URL = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'subscription', 'logout_url'], null)
  );
  const integrations = useSelector((state) => get(state, ['qt', 'config', 'integrations'], {}));

  useEffect(() => {
    const url = new URL(window?.location?.href);
    const client_id = integrations?.sso?.client_id;
    const client_secret = integrations?.sso?.client_secret;
    getSSORedirectUrl(url).then((pageUrl) => {
      setRedirectUrl(pageUrl);
    });
    const authCode = url?.searchParams?.get('authorizationCode');
    const accessToken = document?.cookie
      ?.split(';')
      ?.find((c) => c.indexOf('sso_access_token') > -1);
    const environment = props?.config?.publisher?.environment;
    const accesstypeJwtSecret = integrations?.accesstype?.jwtSecret;
    const metypeJwtSecret = integrations?.metype?.jwtSecret;

    const saveAuthCookies = async () =>
      await verifySSODetails({
        authCode,
        accessToken,
        client_id,
        client_secret,
        environment,
        dispatch,
        accesstypeJwtSecret,
        metypeJwtSecret,
      });
    saveAuthCookies();

    if (window?.location?.search === '?logoutAction=success') {
      window.history.replaceState(null, '', window.location.pathname);
    }
    if (window?.location?.search === '?logoutAction=failure') {
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn === null) {
      setShowBtnLoader(true);
    } else {
      setShowBtnLoader(false);
    }
  }, [isLoggedIn]);

  const toggleDropdownMenu = () => {
    if (props.userProfile) {
      setShowDropdownMenu((prevShowDropdownMenu) => {
        if (!prevShowDropdownMenu) {
          document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
        }
        return !prevShowDropdownMenu;
      });
    }
  };

  const handleSupport = () => {
    setIsContactUsOpen(true);
  };

  const closeContactUs = () => {
    setIsContactUsOpen(false);
  };

  const onLogout = async () => {
    await setShowBtnLoader(true);
    await clearSSOUserDetails(dispatch);
    window.location = await getSSOLogoutUrl(
      props?.config?.integrations?.sso?.client_id,
      props?.config?.integrations?.sso?.client_secret,
      getSSORedirectUrl(new URL(window?.location?.href)),
      SSO_LOGOUT_URL
    );
    setShowDropdownMenu(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropdownMenu && !event.target.closest('.modal-content')) {
        document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
        setShowDropdownMenu(false);
      }
    };
    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showDropdownMenu]);

  return (
    <>
      {showBtnLoader ? (
        <div styleName="btn-loading-grey" />
      ) : (
        <div styleName="loggedviews">
          {isLoggedIn ? (
            <LoggedInView toggleDropdownMenu={toggleDropdownMenu} />
          ) : (
            <LoggedOutView />
          )}

          {showDropdownMenu && (
            <>
              <div styleName={`overlay-div ${showDropdownMenu ? 'overlay-open' : ''}`}> </div>
              <DropdownMenu logout={() => onLogout()} handleSupport={handleSupport} />
            </>
          )}

          {isContactUsOpen && <ContactUs onClose={closeContactUs} />}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: get(state, ['userData', 'isLoggedIn'], null),
  userProfile: get(state, ['userData', 'userProfile'], null),
});

const mapDispatchToProps = (dispatch) => ({
  sendUserStatus: () => {
    dispatch({
      type: 'BQ_USER_STATUS',
    });
  },
  sendSubscriptionDetails: (subscriptionDetails) => {
    dispatch({
      type: 'BQ_SUBSCRIPTION_DETAILS',
      data: subscriptionDetails,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberMenuView);
