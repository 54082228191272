import React, { useEffect, useRef } from 'react';
import { object, func } from 'prop-types';

const TrackVisibility = ({ onVisible=()=>null, onHide=()=>null, children }) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= 0.1) {
          onVisible();
        }else{
          onHide();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0.1]
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);

  return <div ref={ref}> {children} </div>;
};

TrackVisibility.propTypes = {
  onVisible: func,
  children: object
};

export default TrackVisibility;
