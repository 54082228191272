/* eslint-disable camelcase */
/* eslint-disable no-lone-blocks */

const MoengageHelper = {};
MoengageHelper.addUser = user => {};
MoengageHelper.userLogin = user => {
  if (user?.uid && user?.firstName && user?.email) {
    const eventData = {
      login_Status: 'logged-in',
      uid: `${user.uid}`,
      userName: user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName,
      userEmail: `${user.email}`
    };
    global.Moengage.add_unique_user_id(user.uid);
    global.Moengage.track_event('user Login', eventData);
    global.Moengage.add_email(user.email);
  } else {
    console.error('Invalid user object');
  }
};

MoengageHelper.addSessionCount = count => {};
MoengageHelper.sessionExhausted = () => {};
MoengageHelper.userKickedOut = () => {};
MoengageHelper.logoutButtonClicked = () => {};
MoengageHelper._30dayfreetrialButtonClicked = () => {};
MoengageHelper._30dayfreetrialTaken = () => {};
MoengageHelper.setAlertTimes = data => {};
MoengageHelper.referralCodeAttribute = referralCode => {};
MoengageHelper.storyView = story => {};
MoengageHelper.storyViewAttribute = headline => {};
MoengageHelper.sectionView = (section, previousSection) => {};
MoengageHelper.sectionViewAttribute = section => {};
MoengageHelper.purchase = (eventName, subscription, invoice) => {};
MoengageHelper.macroStatusAttributes = (subscription, invoice) => {};
MoengageHelper.planSelection = (selectedPlan, purchasedPlans) => {};
MoengageHelper.planSelectionAttributes = (selectedPlan, purchasedPlans) => {};
MoengageHelper.supportFormSubmitted = () => {};
MoengageHelper.educationFormSubmitted = () => {};
MoengageHelper.corporateFormSubmitted = () => {};
MoengageHelper.supportFormStatusAttributes = () => {};
MoengageHelper.portfolioStatus = () => {};
MoengageHelper.portfolioStatusAttributes = () => {};
MoengageHelper.couponCodeApplied = response => {};
MoengageHelper.couponCodeAppliedStatus = response => {};
MoengageHelper.couponCodeRemoved = response => {};
MoengageHelper.couponCodeRemovedStatus = value => {};
MoengageHelper.hardPayWallAttributes = count => {};
MoengageHelper.meteredPayWallAttributes = () => {};
MoengageHelper.walletLinkedStatusAttribute = wallet => {};
MoengageHelper.ppaConvesionStoryAttribute = title => {};
MoengageHelper.loginStatusAttribute = status => {};
MoengageHelper.signedUp = user => {};
MoengageHelper.logout = () => {};
MoengageHelper.hsbcStatusAttribute = status => {};
MoengageHelper.signedUpEvent = () => {};
MoengageHelper.planStatusAttribute = status => {};
MoengageHelper.hardPaywallEvent = () => {};
MoengageHelper.meteredPayWallEvent = () => {};
MoengageHelper.storyRemainingAttribute = count => {};
MoengageHelper.sendBookmarkStatus = isBookmarked => {};
MoengageHelper.freeTrialStatusAttribute = status => {};
MoengageHelper.freeTrialTimestampAttribute = timestamp => {};
MoengageHelper.freeTrialActivatedEvent = attributes => {};
MoengageHelper.sendPhoneNumber = userData => {};
MoengageHelper.stockAddedEvent = data => {};
MoengageHelper.stockAddedAttributes = data => {};
MoengageHelper.setAlertMedia = data => {};
MoengageHelper.removeAlertMedia = data => {};
MoengageHelper.stockDeleteEvent = data => {};
MoengageHelper.stockDeletedAttributes = data => {};

export default MoengageHelper;
