import React from 'react';
import { func, string } from 'prop-types';

const RightArrowSmall = ({ color = 'fourth-color', className = '', onClick }) => (
  <i
    style={{
      color: `var(--${color}) !important`
    }}
    className={`icon-ic_arrow_forward ${className}`}
    onClick={onClick}
  />
);

RightArrowSmall.propTypes = {
  color: string,
  className: string,
  onClick: func
};

RightArrowSmall.defaultProps = {
  color: 'white'
};

export default RightArrowSmall;
