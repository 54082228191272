import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import take from 'lodash/take';
import MenuItem from '../../atoms/menu-item';
import SubMenuBar from '../../molecules/sub-menu-bar';
import Premium from '../../atoms/logo/premium';
import './main-menu.m.css';
import { getStaticConfig } from '../../../helpers/utils';

const MainMenu = React.memo(({ showMenuBar }) => {
  // Local state to cache menu data to avoid re-rendering issues during rehydration
  let menus = [];
  if (typeof window !== 'undefined') {
    menus = take(
      getStaticConfig('layout')?.menu?.filter(
        (item) => item['menu-group-slug'] === 'web-topbar-menu',
      ),
      7,
    );
  }
  const worldsummit = useSelector((state) =>
    get(state, ['qt', 'config', 'features', 'worldsummit'], {}),
  );

  // Render home item or skeleton
  const renderHomeItem = () => (
    <a styleName={`home-item ${showMenuBar ? 'mb-home-item' : ''}`} href="/?src=top_nav">
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 22.775 19.359">
        <path
          fill="var(--regent-gray)"
          d="M11.11,22.359V15.526h4.555v6.833h5.694v-9.11h3.416L13.388,3,2,13.249H5.416v9.11Z"
          transform="translate(-2 -3)"
        />
      </svg>
    </a>
  );

  // Render skeleton items
  const renderSkeletonItems = () =>
    new Array(8).fill(null).map((_, i) => <span styleName="menu-item-skeleton" key={i + 1} />);

  if (menus.length === 0) {
    return (
      <div styleName={`main-menu ${showMenuBar ? 'left-align' : ''}`}>{renderSkeletonItems()}</div>
    );
  }

  return (
    <div styleName={`main-menu ${showMenuBar ? 'left-align' : ''}`}>
      {menus.length > 0 ? renderHomeItem() : <div />}
      {menus.length > 0 ? (
        menus.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem
              hideIcon={!(item.children && item.children.length)}
              href={
                `${item.title === worldsummit.name ? `${worldsummit?.link}?${worldsummit?.utm}` : `${item.completeUrl}?src=topnav`}` ||
                '/'
              }
              target={item.title === worldsummit.name ? '_blank' : '_self'}
              style={{ color: item?.data?.color || '#2b2b2b' }}
            >
              {item.completeUrl === '/premium' ? (
                <Premium fontSize="16px" />
              ) : item.completeUrl === '/budget' ? (
                <img
                  src="/icons/budget-logo.svg"
                  style={{ marginBottom: '0px' }}
                  width="74px"
                  height="11px"
                />
              ) : item.title === worldsummit.name ? (
                <img
                  src={worldsummit?.desk_img}
                  height={worldsummit?.mb_height}
                  width={worldsummit?.width}
                  alt={worldsummit?.name}
                  styleName="world-img"
                />
              ) : (
                item.title
              )}
            </MenuItem>
            {item.children && item.children.length ? <SubMenuBar subMenu={item.children} /> : null}
          </React.Fragment>
        ))
      ) : (
        <div />
      )}
    </div>
  );
});

export default MainMenu;
