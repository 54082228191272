import React from 'react';
import { object } from 'prop-types';
import LinkList from '../link-list';
import { COPYRIGHT_LINKS, EXTERNAL, STRINGS } from './../../../constants';
import './copyright-section.m.css';

const CopyrightSection = ({ styling }) => (
  <div styleName="copyright">
    <div styleName="copyright-container" className="container">
      <div styleName="copyright-section">
        <div styleName="copyright-wrapper">
          <LinkList
            links={COPYRIGHT_LINKS}
            gridType="row"
            sectionDivider="dot"
            styleName="f-copyright-bottom"
            styling={{
              listItem: {
                fontWeight: styling && styling.listItem && styling.listItem.fontWeight,
                height: styling && styling.listItem && styling.listItem.height
              }
            }}
          />
          <div styleName="logo-container">
            <a
              styleName="link-bq"
              href={EXTERNAL.NDTV_PROFIT}
              target="_blank"
              rel="noopener noreferrer"
            >
              {STRINGS.GET_YEAR} &copy; {STRINGS.NEW_COPYRIGHT_TEXT}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

CopyrightSection.propTypes = {
  styling: object
};

export default CopyrightSection;
