import React from 'react';
import { SOCIAL_ICONS, STRINGS } from '../../../../constants';
import '../new-footer.module.css';

const SocialShare = () => {
  return (
    <div styleName="list">
      <h5 styleName="heading">{STRINGS.FOLLOW_US}</h5>

      <nav styleName="social-icons">
        {SOCIAL_ICONS &&
          SOCIAL_ICONS.filter((icon) => icon.name !== 'NewsLetter').map((item, index) => (
            <a
              key={index}
              styleName="icon-container"
              target="_blank"
              rel="noopener noreferrer"
              href={item.url}
              aria-label="social-icons"
            >
              <i styleName="icon" className={item.icon_code} />
            </a>
          ))}
      </nav>
    </div>
  );
};

export default SocialShare;
