import React from 'react';
import { string } from 'prop-types';

const CheckMark = ({ color = '#14c539', fontSize = '13px', className = '' }) => (
  <span
    style={{
      color: color,
      fontSize: fontSize
    }}
    className={`icon-ic_done ${className}`}
  />
);

CheckMark.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default CheckMark;
