/* eslint-disable max-len */
import React from 'react';
import { string } from 'prop-types';

const PlayIcon = ({
  width = '63px',
  height = '63px',
  bgColor = 'first-color',
  iconColor = 'bg1-color',
  fillOpacity = '0.503028759',
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 63 63" {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Artboard">
        <rect
          id="Rectangle-26-Copy-2"
          fillOpacity={fillOpacity}
          fill={`var(--${bgColor})`}
          x="0"
          y="0"
          width="62.3913043"
          height="62.3790323"
          rx="31.1895161"
        />
        <path
          d="M26.4151289,41.4080489 C25.6335748,42.0091261 25,41.7035016 25,40.7109339 L25,22.0036312 C25,21.017548 25.6316595,20.703966 26.4151289,21.3065163 L38.0685667,30.268936 C38.8501208,30.8700132 38.8520361,31.8430789 38.0685667,32.4456292 L26.4151289,41.4080489 Z"
          id="Triangle-1-Copy-2"
          fill={`var(--${iconColor})`}
        />
      </g>
    </g>
  </svg>
);

PlayIcon.propTypes = {
  width: string,
  height: string,
  bgColor: string,
  iconColor: string,
  fillOpacity: string
};

export default PlayIcon;
