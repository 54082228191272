import { PAGE_TYPE } from './constants';
import { pickComponentHelper } from '@quintype/framework/server/pick-component-helper';

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: 'home', component: 'HomePage' },
    [PAGE_TYPE.HOME_PAGE_TEST]: { chunk: 'home', component: 'HomePageTest' },
    [PAGE_TYPE.SECTION_PAGE_TEST]: { chunk: 'section', component: 'SectionPageTest' },
    [PAGE_TYPE.STORY_PAGE_TEST]: { chunk: 'story', component: 'StoryPageTest' },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: 'section', component: 'SectionPage' },
    [PAGE_TYPE.VIDEOS_PAGE]: { chunk: 'videos', component: 'VideosPage' },
    [PAGE_TYPE.LIVETV_PAGE]: { chunk: 'livetv', component: 'LiveTVPage' },
    [PAGE_TYPE.PODCAST_PAGE]: { chunk: 'list', component: 'PodcastPage' },
    [PAGE_TYPE.IPOS_TRACKER]: { chunk: 'section', component: 'IposTracker' },
    [PAGE_TYPE.TAG_PAGE]: { chunk: 'list', component: 'TagPage' },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: 'list', component: 'AuthorPage' },
    [PAGE_TYPE.AUTHORS_PAGE]: { chunk: 'list', component: 'AuthorsPage' },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: 'list', component: 'SearchPage' },
    [PAGE_TYPE.STORY_PAGE]: { chunk: 'story', component: 'StoryPage' },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: 'list', component: 'CatalogPage' },
    [PAGE_TYPE.PLAYLIST_PAGE]: { chunk: 'videos', component: 'PlaylistPage' },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: 'story', component: 'StoryPagePreview' },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: 'story', component: 'StoryPublicPreview' },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: 'home', component: 'HomePagePreview' },
    [PAGE_TYPE.SUBSCRIPTION_PAGE]: { chunk: 'subscribe', component: 'SubscriptionPage' },
    [PAGE_TYPE.VERIFY_EMAIL_PAGE]: { chunk: 'subscribe', component: 'VerifyEmailPage' },
    [PAGE_TYPE.BREAKOUT_ALERTS_PAGE]: { chunk: 'list', component: 'BreakoutAlerts' },
    [PAGE_TYPE.MYBQ_PAGE]: { chunk: 'portfolio', component: 'MyAccountPage' },
    [PAGE_TYPE.AMP_LOGIN]: { chunk: 'story', component: 'AmpLogin' },
    [PAGE_TYPE.SITEMAP_PAGE]: { chunk: 'sitemap', component: 'Sitemap' },
    [PAGE_TYPE.SECTION_VIDEO_PAGE]: { chunk: 'videos', component: 'SectionVideoPage' },
    [PAGE_TYPE.LOGIN]: { chunk: 'list', component: 'AuthPages' },
    [PAGE_TYPE.SIGN_UP]: { chunk: 'list', component: 'AuthPages' },
    [PAGE_TYPE.SIGN_UP_SUCCESS]: { chunk: 'list', component: 'ThankYou' },
    [PAGE_TYPE.SUBSCRIBE_PUSH_NOTIFICATION]: {
      chunk: 'list',
      component: 'SubscribePushNotification',
    },
    [PAGE_TYPE.NOT_FOUND]: { chunk: 'list', component: 'NotFoundPage' },
    [PAGE_TYPE.SUBSCRIPTION_PAGE_IPO]: { chunk: 'subscribe', component: 'SubscriptionPageIpo' },
    [PAGE_TYPE.SUBSCRIPTION_PAGE_DIWALI]: {
      chunk: 'subscribe',
      component: 'SubscriptionPageDiwali',
    },
    [PAGE_TYPE.BQ_EDGE_EVENTS_DIWALI]: { chunk: 'subscribe', component: 'BqEdgeEventsDiwali' },
    [PAGE_TYPE.MARKET_PAGE]: { chunk: 'market', component: 'MarketPage' },
    [PAGE_TYPE.STOCK_PAGE]: { chunk: 'market', component: 'StockPage' },
    [PAGE_TYPE.INDIAN_INDICES_PAGE]: { chunk: 'market', component: 'IndianIndicesExternalPage' },
    [PAGE_TYPE.SECTORAL_INDICES_PAGE]: {
      chunk: 'market',
      component: 'SectoralIndicesExternalPage',
    },
    [PAGE_TYPE.CORPORATE_ACTIONS_PAGE]: {
      chunk: 'market',
      component: 'CorporateActionsExternalPage',
    },
    [PAGE_TYPE.HISTORICAL_RETURNS_PAGE]: {
      chunk: 'market',
      component: 'HistoricalReturnsExternalPage',
    },
    [PAGE_TYPE.STOCKS_IN_INDICES_PAGE]: {
      chunk: 'market',
      component: 'StocksInIndicesExternalPage',
    },
    [PAGE_TYPE.SECTORAL_STOCKS_IN_INDICES_PAGE]: {
      chunk: 'market',
      component: 'SectoralStocksInIndicesExternalPage',
    },
    [PAGE_TYPE.FUTURES_PAGE]: {
      chunk: 'market',
      component: 'FuturesPage',
    },
    [PAGE_TYPE.OPEN_INTEREST_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalOpenInterest',
    },
    [PAGE_TYPE.GREATEST_PREMIUM_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalGreatestPremium',
    },
    [PAGE_TYPE.FUTURES_ROLLOVER_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalRollover',
    },
    [PAGE_TYPE.FUTURES_SHORT_COVERING_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalShortCovering',
    },
    [PAGE_TYPE.FUTURES_MOST_ACTIVE_CONTRACTS_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalMostActiveContracts',
    },
    [PAGE_TYPE.FUTURES_SECTORAL_MOVEMENTS_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalSectoralMovements',
    },
    [PAGE_TYPE.FUTURES_FILTER_PAGE]: {
      chunk: 'market',
      component: 'FuturesExternalFiltered',
    },
    [PAGE_TYPE.OPTIONS_PAGE]: {
      chunk: 'market',
      component: 'OptionsPage',
    },
    [PAGE_TYPE.OPTIONS_MOST_ACTIVE_INDEX]: {
      chunk: 'market',
      component: 'OptionsExternalMostActiveIndex',
    },
    [PAGE_TYPE.OPTIONS_MOST_ACTIVE_STOCK]: {
      chunk: 'market',
      component: 'OptionsExternalMostActiveStock',
    },
    [PAGE_TYPE.OPTIONS_TOP_OPEN_INDEX]: {
      chunk: 'market',
      component: 'OptionsExternalTopOpenInterestIndex',
    },
    [PAGE_TYPE.OPTIONS_TOP_OPEN_STOCK]: {
      chunk: 'market',
      component: 'OptionsExternalTopOpenInterestStock',
    },
    [PAGE_TYPE.OPTIONS_OPEN_INTEREST_BREAKUP]: {
      chunk: 'market',
      component: 'OptionsExternalOpenInterestBreakup',
    },
    [PAGE_TYPE.OPTIONS_OPEN_INTEREST_CHANGE]: {
      chunk: 'market',
      component: 'OptionsExternalOpenInterestChange',
    },
    [PAGE_TYPE.OPTIONS_OI_LAST_EXPIRY]: {
      chunk: 'market',
      component: 'OptionsExternalOIChangeLastExpiry',
    },
    [PAGE_TYPE.OPTIONS_PUT_CALL_RATIO]: {
      chunk: 'market',
      component: 'OptionsExternalPutCallRatio',
    },
    default: { chunk: 'list', component: 'NotFoundPage' },
    [PAGE_TYPE.FEED_COUNTER_DASHBOARD]: { chunk: 'list', component: 'FeedCounterDashboard' },
    [PAGE_TYPE.EDITORIAL_CODE_OF_CONDUCT]: { chunk: 'list', component: 'EditorialCodeOfConduct' },

    // INDIVIDUAL MARKET WIDGETS
    [PAGE_TYPE.INDICES_WIDGET]: { chunk: 'list', component: 'IndianIndicesWidget' },
    [PAGE_TYPE.FUTURES_GRAPH_WIDGET]: { chunk: 'list', component: 'FuturesGraphWidget' },
    [PAGE_TYPE.MARKETS_NEWS_WIDGET]: { chunk: 'list', component: 'MarketsNewsWidget' },
    [PAGE_TYPE.STOCK_STATS_WIDGET]: { chunk: 'list', component: 'StockStatsWidget' },

    // FULL PAGE WIDGETS
    [PAGE_TYPE.LIVE_TV_WIDGET]: { chunk: 'list', component: 'LiveTvWidget' },
    [PAGE_TYPE.MARKETS_FULL_WIDGET]: { chunk: 'list', component: 'MarketsFullWidget' },
    [PAGE_TYPE.STOCK_FULL_WIDGET]: { chunk: 'list', component: 'StockFullWidget' },
    [PAGE_TYPE.FUTURES_CONTRACT_FULL_WIDGET]: {
      chunk: 'list',
      component: 'FuturesContractFullWidget',
    },
    [PAGE_TYPE.NDTV_MARKET_WIDGET]: { chunk: 'list', component: 'NdtvMarketWidget' },
    [PAGE_TYPE.KEY_HIGHLIGHTS_WIDGET]: {chunk: 'list', component: 'KeyHighlightsWidget'},
    [PAGE_TYPE.DAILYHUNT_MARKET_WIDGET]: { chunk: 'list', component: 'NdtvMarketWidget' },
    [PAGE_TYPE.STOCKS_IN_FOCUS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'StocksInFocusFullWidget',
    },
    [PAGE_TYPE.STATE_OF_THE_ECONOMY_FULL_WIDGET]: {
      chunk: 'list',
      component: 'StateOfTheEconomyFullWidget',
    },
    [PAGE_TYPE.OPTIONS_CHAIN_FULL_WIDGET]: { chunk: 'list', component: 'OptionChainFullWidget' },
    [PAGE_TYPE.EQ_ALL_FULL_WIDGET]: { chunk: 'list', component: 'MarketsEqAllFullWidget' },
    [PAGE_TYPE.STOCK_IN_INDICES_FULL_WIDGET]: {
      chunk: 'list',
      component: 'StockIndicesFullWidget',
    },
    [PAGE_TYPE.HISTORICAL_RETURNS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'HistoricalReturnsFullWidget',
    },
    [PAGE_TYPE.SECTORAL_INDICES_FULL_WIDGET]: {
      chunk: 'list',
      component: 'SectoralIndicesFullWidget',
    },
    [PAGE_TYPE.SECTORAL_STOCK_IN_INDICES_FULL_WIDGET]: {
      chunk: 'list',
      component: 'SectoralStockIndicesFullWidget',
    },
    [PAGE_TYPE.CALENDER_EVENTS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'CalenderEventsFullWidget',
    },
    [PAGE_TYPE.SHORT_COVERING_FULL_WIDGET]: { chunk: 'list', component: 'ShortCoveringFullWidget' },
    [PAGE_TYPE.MOST_ACTIVE_CONTRACTS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'MostActiveContractsFullWidget',
    },
    [PAGE_TYPE.SECTORAL_MOVEMENTS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'SectoralMovementsFullWidget',
    },
    [PAGE_TYPE.MOST_ACTIVE_INDEX_OPTIONS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'MostActiveIndexOptionsFullWidget',
    },
    [PAGE_TYPE.MOST_ACTIVE_STOCK_OPTIONS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'MostActiveStockOptionsFullWidget',
    },
    [PAGE_TYPE.TOP_TEN_INT_INDEX_OPTIONS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'TopTenIntIndexOptionsFullWidget',
    },
    [PAGE_TYPE.TOP_TEN_INT_STOCK_OPTIONS_FULL_WIDGET]: {
      chunk: 'list',
      component: 'TopTenIntIndexOptionsFullWidget',
    },
    [PAGE_TYPE.OPEN_INTEREST_BREAKUP_FULL_WIDGET]: {
      chunk: 'list',
      component: 'OpenInterestBreakupFullWidget',
    },
    [PAGE_TYPE.FUTURES_OI_CHANGE_SINCE_LAST_EXPIRY]: {
      chunk: 'list',
      component: 'FuturesOIChangeSinceLastExpiryFullWidget',
    },
    [PAGE_TYPE.OPTIONS_OI_CHANGE_SINCE_LAST_EXPIRY]: {
      chunk: 'list',
      component: 'OptionsOIChangeSinceLastExpiryFullWidget',
    },
    [PAGE_TYPE.PUT_CALL_RATIO_FULL_WIDGET]: { chunk: 'list', component: 'PutCallRatioFullWidget' },
    [PAGE_TYPE.TOPIC_FULL_WIDGET]: { chunk: 'list', component: 'TopicFullWidget' },
    [PAGE_TYPE.GREATEST_PREMIUM_FULL_WIDGET]: {
      chunk: 'list',
      component: 'GreatestPremiumFullWidget',
    },
    [PAGE_TYPE.FUTURES_ROLLOVER_FULL_WIDGET]: {
      chunk: 'list',
      component: 'FuturesRolloverFullWidget',
    },
    [PAGE_TYPE.BUDGET_STOCKS_IN_FOCUS_LIVE]: {
      chunk: 'list',
      component: 'StocksInFocusLiveFullWidget',
    },
    [PAGE_TYPE.OPEN_INTEREST_FULL_WIDGET]: { chunk: 'list', component: 'OpenInterestFullWidget' },

    [PAGE_TYPE.ADVANCES_DECLINES_WIDGET]: { chunk: 'list', component: 'AdvancesDeclinesWidget' },
    [PAGE_TYPE.BUDGET_IMPACT_WIDGET]: {
      chunk: 'list',
      component: 'BudgetImpactFullWidget',
    },
    [PAGE_TYPE.BUDGET_PAGE]: { chunk: 'budget', component: 'BudgetPage' },
    [PAGE_TYPE.BUSINESS_CALCULATORS]: {
      chunk: 'businessCalculators',
      component: 'CalculatorListing',
    },
    [PAGE_TYPE.HOME_LOAN]: {
      chunk: 'businessCalculators',
      component: 'HomeLoan',
    },
    [PAGE_TYPE.CAR_LOAN]: {
      chunk: 'businessCalculators',
      component: 'CarLoan',
    },
    [PAGE_TYPE.EDUCATION_LOAN]: {
      chunk: 'businessCalculators',
      component: 'EducationLoan',
    },
    [PAGE_TYPE.PERSONAL_LOAN]: {
      chunk: 'businessCalculators',
      component: 'PersonalLoan',
    },
    [PAGE_TYPE.FIXED_DEPOSIT]: {
      chunk: 'businessCalculators',
      component: 'FixedDeposit',
    },
    [PAGE_TYPE.RECURRING_DEPOSIT]: {
      chunk: 'businessCalculators',
      component: 'RecurringDeposit',
    },
    [PAGE_TYPE.PUBLIC_PROVIDENT_FUND]: {
      chunk: 'businessCalculators',
      component: 'PublicProvidentFund',
    },
    [PAGE_TYPE.SIP]: {
      chunk: 'businessCalculators',
      component: 'Sip',
    },
    [PAGE_TYPE.INCOME_TAX]: {
      chunk: 'businessCalculators',
      component: 'IncomeTax',
    },
    [PAGE_TYPE.BREAKOUT_ALERTS_WIDGET]: { chunk: 'list', component: 'BreakoutAlertsFullWidget' },
  },
  {
    home: () => import(/* webpackChunkName: "home" */ './component-bundles/home.js'),
    story: () => import(/* webpackChunkName: "story" */ './component-bundles/story.js'),
    section: () => import(/* webpackChunkName: "section" */ './component-bundles/section.js'),
    list: () => import(/* webpackChunkName: "list" */ './component-bundles/list.js'),
    market: () => import(/* webpackChunkName: "market" */ './component-bundles/market.js'),
    videos: () => import(/* webpackChunkName: "videos" */ './component-bundles/videos.js'),
    livetv: () => import(/* webpackChunkName: "videos" */ './component-bundles/livetv.js'),
    podcast: () => import(/* webpackChunkName: "videos" */ './component-bundles/podcast.js'),
    subscribe: () => import(/* webpackChunkName: "subscribe" */ './component-bundles/subscribe.js'),
    portfolio: () => import(/* webpackChunkName: "portfolio" */ './component-bundles/portfolio.js'),
    sitemap: () => import(/* webpackChunkName: "sitemap" */ './component-bundles/sitemap.js'),
    budget: () => import(/* webpackChunkName: "budget" */ './component-bundles/budget.js'),
    businessCalculators: () =>
      import(
        /* webpackChunkName: "businessCalculators" */ './component-bundles/business-calculators.js'
      ),
  }
);

export { pickComponent, getChunkName };
