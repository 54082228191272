import React from 'react';
import { string } from 'prop-types';

const EyeIcon = ({ color = '#9AA6B9', fontSize = '10px', className = '' }) => (
  <span
    style={{
      color: color,
      fontSize: fontSize
    }}
    className={`icon-ic_eye ${className}`}
  />
);

EyeIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default EyeIcon;
