import React from 'react';
import { isTablet } from 'react-device-detect';
import './Landscape-orientation.m.css';
function LandscapeOrientation() {
  return (
    <div styleName="screen-orientation">
      {isTablet && (
        <div styleName="main">
          <img styleName="screen-image" src='/images/portrait.png' />
          <p styleName="content">
            Rotate to <b>Landscape Mode</b>
          </p>
          <p styleName="sub-content">for the best reading Experience on NDTV Profit</p>
        </div>
      )}
    </div>
  );
}

export default LandscapeOrientation;
