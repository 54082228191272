import React from 'react';
import { string } from 'prop-types';

const SupportIcon = ({ color = 'border6-color', fontSize = 'var(--base)', className = '' }) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_help ${className}`}
  />
);

SupportIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default SupportIcon;
