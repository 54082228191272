import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';

const MaterialIconWrapper = ({
  iconName,
  size = '20px',
  title = '',
  description = '',
  horizontal = false,
  vertical = false,
  rotate = 0,
  color = '#b7bfc4',
  spin = false,
}) => {
  return (
    <Icon
      path={iconName}
      size={size}
      title={title}
      description={description}
      horizontal={horizontal}
      vertical={vertical}
      rotate={rotate}
      color={color}
      spin={spin}
    />
  );
};

MaterialIconWrapper.propTypes = {
  iconName: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  rotate: PropTypes.number,
  color: PropTypes.string,
  spin: PropTypes.bool || PropTypes.number,
};

export default MaterialIconWrapper;
