/* eslint-disable max-len */
import React from 'react';
import { arrayOf, objectOf, any, object, string } from 'prop-types';
import get from 'lodash/get';
import { getHeroImage, getStoryHeading } from '../../../helpers/utils';
import { BQLink } from '../../atoms/bqlink';
import '../notifications.m.css';
import CardImage from '../../atoms/cardimage';

const NotificationStories = ({ stories, config }) => {
  if (!stories.length) return null;
  return stories.map(({ story }) => {
    const headline = getStoryHeading(story);
    const author = get(story, ['author-name'], '');

    return (
      <article key={story.id} styleName="story">
        <BQLink href={`/${story.slug}?src=stry_bell`} styleName="story-image">
          <CardImage story={story} imageType="image1x1" aspectRatio={[6, 5]} isLazy={false} />
        </BQLink>
        <BQLink href={`/${story.slug}?src=stry_bell`}>
          <h3 styleName="story-headline">{headline}</h3>
          <span styleName="story-author">{author}</span>
        </BQLink>
      </article>
    );
  });
};

NotificationStories.propTypes = {
  stories: arrayOf(object).isRequired,
  config: objectOf(any).isRequired
};

export default NotificationStories;
