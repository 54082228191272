const BQNotifications = {};

BQNotifications.updateUnreadCount = (memberId, count) => {
  return fetch(`/fireapi/notifications/member`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ member_id: memberId, unread_count: count })
  });
};

BQNotifications.acknowledge = memberId => {
  try {
    fetch('/fireapi/notifications/member', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        member_id: memberId,
        last_viewed_at: Date.now(),
        unread_count: 0
      })
    });
  } catch (error) {
    console.error(error);
  }
};

BQNotifications.sync = async (member, collections, currentCount) => {
  try {
    const lastViewedAt = await BQNotifications.getLastViewedAt(member);
    const newStories = await BQNotifications.getNewStories(collections, lastViewedAt);
    if (currentCount < newStories && newStories.length) {
      BQNotifications.updateUnreadCount(member.uid, newStories.length);
      return newStories.length;
    }
  } catch (error) {
    console.error(error);
  }
};

BQNotifications.getNewStories = async (collections, lastViewedAt) => {
  const response = await fetch(
    `/api/v1/collections/${collections}?limit=100&fields=updated-at,metadata`
  );
  const { items } = await response.json();
  const newStories =
    items &&
    items.filter(item => {
      const storyAttributes = item.story.metadata['story-attributes'];
      const hasDoNotNotify =
        storyAttributes &&
        storyAttributes.actions &&
        storyAttributes.actions.includes('do not notify');
      if (item.story['updated-at'] > lastViewedAt && !hasDoNotNotify) {
        return item;
      }
    });
  return newStories;
};

BQNotifications.getLastViewedAt = async member => {
  const response = await fetch(`/fireapi/notifications/member/${member.uid}`);
  const memberData = await response.json();

  if (memberData.data && memberData.data.last_viewed_at) {
    return memberData.data.last_viewed_at;
  } else {
    return member['created-at'];
  }
};

export default BQNotifications;
