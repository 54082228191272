import wretch from 'wretch';

export function postCall(url, data, headers = {}) {
  return wretch()
    .options({ credentials: 'same-origin', headers })
    .url(url)
    .post(data);
}

export function getCall(url) {
  return wretch()
    .options({ credentials: 'same-origin' })
    .url(url)
    .get();
}

export function patchCall(url, data, headers = {}) {
  return wretch()
    .options({ credentials: 'same-origin', headers })
    .url(url)
    .patch(data);
}
