import React from 'react';
import { Toaster } from 'react-hot-toast';

function ToasterMessage () {
  return (
    <React.Fragment>
      <Toaster
      position="bottom-center"
      toastOptions={{
        duration: 5000,
        style: {
          backgroundColor: '#000',
          color: '#FFF',
          fontFamily: 'Roboto',
          fontSize: 16,
          borderRadius: 22,
          marginBottom: '3rem',
          width: 'max-content'
        }
      }}
      />
    </React.Fragment>
  )
};

export { ToasterMessage };