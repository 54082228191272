import React from 'react';
import { func, objectOf, string, any, number } from 'prop-types';
import NotificationStories from '../notification-stories';
import RightArrowSmall from '../../atoms/icons/right-arrow-small';
import { BQLink } from '../../atoms/bqlink';
import '../notifications.m.css';

class NotificationPopover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stories: [],
      loader: true
    };
    this.fetchStories = this.fetchStories.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.handleEscape = this.handleEscape.bind(this);
    this.popoverRef = React.createRef();
  }

  componentDidMount() {
    this.fetchStories();
    global && document.addEventListener('click', this.handleOutsideClick);
    global && document.addEventListener('keyup', this.handleEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
    document.removeEventListener('keyup', this.handleEscape);
  }

  /* eslint-disable */
  async fetchStories() {
    const url = `/api/v1/collections/${this.props.collectionSlug}?limit=${this.props.storyLimit}&fields=headline,slug,hero-image-s3-key,alternative,author-name`;
    try {
      const response = await (await fetch(url)).json();
      this.setState({ loader: false });
      response && response.items && this.setState({ stories: response.items });
    } catch (error) {
      console.error(error);
      this.setState({ loader: false });
    }
  }

  handleOutsideClick(e) {
    !this.popoverRef.current.contains(e.target) && this.props.closePopover();
  }

  handleEscape(e) {
    e.keyCode === '27' && this.props.closePopover();
  }

  render() {
    const { closePopover, config, collectionSlug } = this.props;
    const { stories, loader } = this.state;
    const message = loader ? 'Loading...' : "You don't have any notifications.";
    return (
      <section data-testid="notification-popover" styleName="popover" ref={this.popoverRef}>
        <div styleName="header">
          <h2 styleName="title">Notifications</h2>
          <button
            data-testid="notification-close-button"
            styleName="close-button"
            onClick={closePopover}
          />
        </div>
        <div styleName="content">
          {stories.length ? (
            <NotificationStories stories={stories} config={config} />
          ) : (
            <div styleName="info-message">{message}</div>
          )}
        </div>
        <div>
          {!!stories.length && (
            <div styleName="footer">
              <BQLink href={`/${collectionSlug}?src=bell`} styleName="read-more-link">
                View All <RightArrowSmall color="fourth-color" />
              </BQLink>
            </div>
          )}
        </div>
      </section>
    );
  }
}

NotificationPopover.propTypes = {
  closePopover: func.isRequired,
  config: objectOf(any).isRequired,
  collectionSlug: string.isRequired,
  storyLimit: number.isRequired
};

export default NotificationPopover;
