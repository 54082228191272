import React from 'react';
import {
  GavelIcon,
  FireIcon,
  StarCircleOutlineIcon,
  ChartBoxOutlineIcon,
  TicketConfirmationOutlineIcon,
  LibraryOutlineIcon,
  LayersOutlineIcon,
  CurrencyBtcIcon,
  FinanceIcon,
  TextBoxIcon,
  PodiumGoldIcon,
  TrendingUpIcon,
  VirusIcon,
  VoteOutlineIcon,
  FileSignIcon,
  SafeIcon,
  HandShakeOutlineIcon,
  GoldIcon,
  MicrophoneVariantIcon,
  CommentQuoteOutlineIcon,
  SchoolOutlineIcon,
  WindTurbineIcon,
  WebIcon,
  UmbrellaBeachOutlineIcon,
  MemoryIcon,
  ViewCarouselIcon,
  BankIcon,
  NotificationsIcon,
  PlusIcon,
  AccountGroupIcon,
  AccountCircleIcon,
  ContentCopyIcon,
  ChevronDownIcon,
  MenuDownIcon,
  PlayIcon,
  PlayCircleIcon,
  ChevronRightIcon,
  DiceIcon,
  PlayOutlineIcon,
  AlertIcon,
  ShareVariantOutlineIcon,
  BlurIcon,
  WalletGiftCardIcon,
  PowerIcon,
  MenuIcon,
  HistoryIcon,
  BinocularsIcon,
  CreditCardIcon,
  ForumIcon,
  InformationOutlineIcon,
  CheckIcon,
  AttachementIcon,
  PauseIcon,
  BroadcastIcon,
  LightBulbOnIcon,
  EyeOffIcon,
  FullScreenIcon,
  PlayCircleOutlineIcon,
  MaginifyIcon,
  ClockOutlineIcon,
  EyeIcon,
  ArrowLeftIcon,
  InstagramIcon,
  PencilIcon,
  YouTubeIcon,
  CheckboxBlankIcon,
  CheckboxMarkedIcon,
  TrendingDownIcon,
  ArrowRightIcon,
  FacebookIcon,
  CurrencyInrIcon,
  FilmStripIcon,
  FormatQuoteCloseIcon,
  ChartTimeVariantIcon,
  UploadIcon,
  CalendarMonthIcon,
  ChevronTripLeftIcon,
  SwapHorizontalIcon,
  CancelIcon,
  CloseIcon,
  CogOutlineIcon,
  CogIcon,
  MessageOutlineIcon,
  ChatOutlineIcon,
  ArrowDownIcon,
  LockOutlineIcon,
  CastConnectedIcon,
  SaleIcon,
  BookVariantIcon,
  FileDocumentIcon,
  MinusCircleOffIcon,
  StarIcon,
  BookOpenIcon,
  SchoolIcon,
  MoonWarningCrescentIcon,
  RadioBoxMarkedIcon,
  DeleteIcon,
  FoodDrumstickIcon,
  WhiteBalanceSunnyIcon,
  WeatherSunnyIcon,
  DownloadIcon,
  HelpCircleOutlineIcon,
  EmailIcon,
  WifiOffIcon,
  AccountBadgeIcon,
  CookieIcon,
  BookMarkCheckOutOutlineIcon,
  CheckDecagramIcon,
  ReceiptTextIcon,
  TimerIcon,
  AlertOctagonIcon,
  BookmarkIcon,
  BookmarkOutlineIcon,
  TwitterIcon,
  LinkedInIcon,
  WhatsappIcon,
  RedditIcon,
  BellIcon,
  UmbrellaBeachIcon,
  FlashIcon,
  MonitorIcon,
  OpenInNewIcon,
  ListBoxOutlineIcon,
  CalculatorIcon,
  HomeAnalyticsIcon,
  CrystalBallIcon,
  ChartPieOutlineIcon,
  BookSearchOutlineIcon,
  OrderAlphabeticalAscendingIcon,
  FileChartOutlineIcon,
  StateMachineIcon,
  FileCompareIcon,
  DatabaseOutlineIcon,
  AbacusIcon,
  ChartTimelineVariantShimmerIcon,
  BlurLinearIcon,
  VideoCheckoutOutlineIcon,
  ChartTimelineIcon,
  PerecentBoxOutlineIcon,
  CarBrakeHoldIcon,
  BullHornVariantOutlineIcon,
  CricketIcon,
  PiggyBankIcon,
  VoteIcon
} from './index';

const menuIcons = {
  // Menu Icon codes
  'icon-ic_law': <GavelIcon />,
  'icon-ic_latest': <FireIcon />,
  'icon-ic_budget': <CurrencyInrIcon />,
  'icon-ic_exclusives': <StarCircleOutlineIcon />,
  'icon-ic_fund': <ChartBoxOutlineIcon />,
  'icon-ic_edge': <TicketConfirmationOutlineIcon />,
  'icon-ic_legal': <LibraryOutlineIcon />,
  'icon-ic_gst': <BankIcon />,
  'icon-ic_bweek': <LayersOutlineIcon />,
  'icon-ic_crypto': <CurrencyBtcIcon />,
  'icon-ic_markets': <FinanceIcon />,
  'icon-ic_reports': <TextBoxIcon />,
  'icon-ic_ipo': <PodiumGoldIcon />,
  'icon-ic_videos': <FilmStripIcon />,
  'icon-ic_trending': <TrendingUpIcon />,
  'icon-ic_corona': <VirusIcon />,
  'icon-ic_politics': <VoteOutlineIcon />,
  'icon-ic_finterms': <FileSignIcon />,
  'icon-ic_earnings': <SafeIcon />,
  'icon-ic_business': <HandShakeOutlineIcon />,
  'icon-ic_efinance': <GoldIcon />,
  'icon-ic_davos': <MicrophoneVariantIcon />,
  'icon-ic_opinion': <CommentQuoteOutlineIcon />,
  'icon-ic_learning': <SchoolOutlineIcon />,
  'icon-ic_esg': <WindTurbineIcon />,
  'icon-ic_globalecon': <WebIcon />,
  'icon-ic_pursuits': <UmbrellaBeachOutlineIcon />,
  'icon-ic_tech': <MemoryIcon />,
  'icon-ic_vstories': <ViewCarouselIcon />,
  'icon-ic-sports': <CricketIcon />,

  // Non-menu icon codes
  'icon-ic_notifications': <NotificationsIcon />,
  'icon-ic_add': <PlusIcon />,
  'icon-ic_group': <AccountGroupIcon />,
  'icon-ic_profile': <AccountCircleIcon />,
  'icon-ic_copy': <ContentCopyIcon />,
  'icon-ic_arrow_dropdown_active': <ChevronDownIcon />,
  'icon-ic_arrow_dropdown': <MenuDownIcon />,
  'icon-ic_play_filled': <PlayIcon />,
  'icon-ic_play_filled_alt': <PlayCircleIcon />,
  'icon-ic_chevron_right': <ChevronRightIcon />,
  'icon-ic_dice': <DiceIcon />,
  'icon-ic_play': <PlayOutlineIcon />,
  'icon-ic_warning': <AlertIcon />,
  'icon-ic_share_outlined': <ShareVariantOutlineIcon />,
  'icon-ic_generic': <BlurIcon />,
  'icon-ic_gift': <WalletGiftCardIcon />,
  'icon-ic_power': <PowerIcon />,
  'icon-ic_technology': <MemoryIcon />,
  'icon-ic_menu': <MenuIcon />,
  'icon-ic_history': <HistoryIcon />,
  'icon-ic_binoculars': <BinocularsIcon />,
  'icon-ic_feed': <ForumIcon />,
  'icon-ic_card': <CreditCardIcon />,
  'icon-ic_menu_slit': <ForumIcon />,
  'icon-ic_info_outline': <InformationOutlineIcon />,
  'icon-ic_done': <CheckIcon />,
  'icon-ic_attachment': <AttachementIcon />,
  'icon-ic_pause': <PauseIcon />,
  'icon-ic_broadcast': <BroadcastIcon />,
  'icon-ic_lightbulb': <LightBulbOnIcon />,
  'icon-ic_add_outline': <PlusIcon />,
  'icon-ic_hide': <EyeOffIcon />,
  'icon-ic_fullscreen': <FullScreenIcon />,
  'icon-ic_close': <CloseIcon />,
  'icon-ic_play_outline': <PlayCircleOutlineIcon />,
  'icon-ic_covid': <VirusIcon />,
  'icon-ic_search': <MaginifyIcon />,
  'icon-ic_timestamp': <ClockOutlineIcon />,
  'icon-ic_world': <WebIcon />,
  'icon-ic_eye': <EyeIcon />,
  'icon-ic_arrow_backward': <ArrowLeftIcon />,
  'icon-ic_instagram': <InstagramIcon />,
  'icon-ic_edit': <PencilIcon />,
  'icon-ic_market_up': <TrendingUpIcon />,
  'icon-ic_youtube': <YouTubeIcon />,
  'icon-ic_checkbox_empty': <CheckboxBlankIcon />,
  'icon-ic_expand': <ChevronDownIcon />,
  'icon-ic_checkbox_fill': <CheckboxMarkedIcon />,
  'icon-ic_market_down': <TrendingDownIcon />,
  'icon-ic_arrow_forward': <ArrowRightIcon />,
  'icon-ic_share': <ShareVariantOutlineIcon />,
  'icon-ic_saved': <BookMarkCheckOutOutlineIcon />,
  'icon-ic_account': <AccountCircleIcon />,
  'icon-ic_verified': <CheckDecagramIcon />,
  'icon-ic_receipt': <ReceiptTextIcon />,
  'icon-ic_timer': <TimerIcon />,
  'icon-ic_report': <AlertOctagonIcon />,
  'icon-ic_calendar': <CalendarMonthIcon />,
  'icon-ic_save': <BookmarkIcon />,
  'icon-ic_save_alt': <BookmarkOutlineIcon />,
  'icon-ic_twitter': <TwitterIcon />,
  'icon-ic_linkedin': <LinkedInIcon />,
  'icon-ic_whatsapp': <WhatsappIcon />,
  'icon-ic_reddit': <RedditIcon />,
  'icon-ic_mail': <EmailIcon />,
  'icon-ic_notifications_active': <BellIcon />,
  'icon-ic_umbrella': <UmbrellaBeachIcon />,
  'icon-ic_flash': <FlashIcon />,
  'icon-ic_desktop': <MonitorIcon />,
  'icon-ic_facebook': <FacebookIcon />,
  'icon-ic_bookmark': <BookmarkIcon />,
  'icon-ic_bookmark_alt': <BookmarkOutlineIcon />,
  'icon-ic_rupee': <CurrencyInrIcon />,
  'icon-ic_theaters_24px': <FilmStripIcon />,
  'icon-ic_quote': <FormatQuoteCloseIcon />,
  'icon-ic_timeline': <ChartTimeVariantIcon />,
  'icon-ic_upload': <UploadIcon />,
  'icon-ic_calendar1': <CalendarMonthIcon />,
  'icon-ic_tripleback': <ChevronTripLeftIcon />,
  'icon-ic_swap': <SwapHorizontalIcon />,
  'icon-ic_cancel': <CancelIcon />,
  'icon-ic_cancel_alt': <CloseIcon />,
  'icon-ic_settings': <CogOutlineIcon />,
  'icon-ic_settings_alt': <CogIcon />,
  'icon-ic_chat': <MessageOutlineIcon />,
  'icon-ic_chat_alt': <ChatOutlineIcon />,
  'icon-ic_scroll_down': <ChevronDownIcon />,
  'icon-ic_scroll_down_alt': <ArrowDownIcon />,
  'icon-ic_lock': <LockOutlineIcon />,
  'icon-ic_connection': <CastConnectedIcon />,
  'icon-ic_discount': <SaleIcon />,
  'icon-ic_offer': <SaleIcon />,
  'icon-ic_book': <BookVariantIcon />,
  'icon-ic_docs': <FileDocumentIcon />,
  'icon-ic_do_not_disturb': <MinusCircleOffIcon />,
  'icon-ic_star': <StarIcon />,
  'icon-ic_reader_mode': <BookOpenIcon />,
  'icon-ic_team': <AccountGroupIcon />,
  'icon-ic_college': <SchoolIcon />,
  'icon-ic_quotes': <FormatQuoteCloseIcon />,
  'icon-ic_crescent_moon': <MoonWarningCrescentIcon />,
  'icon-ic_bullet': <RadioBoxMarkedIcon />,
  'icon-ic_delete': <DeleteIcon />,
  'icon-ic_kebab': <FoodDrumstickIcon />,
  'icon-ic_visibility_off_24px': <EyeOffIcon />,
  'icon-ic_sun': <WhiteBalanceSunnyIcon />,
  'icon-ic_sunrise': <WeatherSunnyIcon />,
  'icon-ic_download': <DownloadIcon />,
  'icon-ic_help': <HelpCircleOutlineIcon />,
  'icon-ic_mail1': <EmailIcon />,
  'icon-ic_play_alt': <PlayIcon />,
  'icon-ic_offline': <WifiOffIcon />,
  'icon-ic_online': <AccountBadgeIcon />,
  'icon-ic_cookies': <CookieIcon />,

  // Markets and stocks icon codes
  'icon-ic_future': <OpenInNewIcon />,
  'icon-ic_option': <OpenInNewIcon />,
  'icon-ic_indices': <ListBoxOutlineIcon />,
  'icon-ic_calculator': <CalculatorIcon />,
  'icon-ic_stock': <HomeAnalyticsIcon />,
  'icon-ic_f&o': <CrystalBallIcon />,
  'icon-ic_adv&dec': <ChartPieOutlineIcon />,
  'icon-ic_sectindices': <BookSearchOutlineIcon />,
  'icon-ic_topsector': <OrderAlphabeticalAscendingIcon />,
  'icon-ic_stocknews': <FileChartOutlineIcon />,
  'icon-ic_exclusive': <StarCircleOutlineIcon />,
  'icon-ic_stats': <StateMachineIcon />,
  'icon-ic_comprice': <FileCompareIcon />,
  'icon-ic_comvaluation': <DatabaseOutlineIcon />,
  'icon-ic_fundamental': <AbacusIcon />,
  'icon-ic_movingavg': <ChartTimelineVariantShimmerIcon />,
  'icon-ic_deliveryvol': <BlurLinearIcon />,
  'icon-ic_mustwatch': <VideoCheckoutOutlineIcon />,
  'icon-ic_quarterresult': <ChartTimelineIcon />,
  'icon-ic_totalreturns': <PerecentBoxOutlineIcon />,
  'icon-ic_sharehold': <CarBrakeHoldIcon />,
  'icon-ic_announcement': <BullHornVariantOutlineIcon />,
  'icon-ic_pfinance': <PiggyBankIcon />,
  'icon-ic_vote': <VoteIcon />,
  default: <ViewCarouselIcon />
};

export default menuIcons;
