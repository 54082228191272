import React from 'react';
import PropTypes from 'prop-types';

import './hamburger-btn.m.css';

export const HamburgerBtn = ({ onMenuToggle, isMegaMenuOpen, showMenuBar, budgetStrip }) => (
  <button
    aria-label="Hamburger Menu"
    className="hamburger-menu"
    onClick={onMenuToggle}
    styleName={`hamburger ${isMegaMenuOpen ? 'is-open' : ''} ${
      showMenuBar && !budgetStrip ? 'hamburger-changes' : ''
    } ${budgetStrip ? 'budget-strip' : ''}`}
  >
    <div>
      {new Array(3).fill(null).map((element, i) => {
        return <span styleName="line" key={i + 1} />;
      })}
    </div>
    {!showMenuBar ? <div styleName="explore-text">Menu</div> : null}
  </button>
);

HamburgerBtn.propTypes = {
  onMenuToggle: PropTypes.func,
  isMegaMenuOpen: PropTypes.bool,
};

export default HamburgerBtn;
