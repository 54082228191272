import React from 'react';
import { func, bool, string, object, number } from 'prop-types';
import NotificationsIcon from '../../atoms/icons/notifications-icon';
import BQNotifications from '../../../helpers/notifications-helper';
import { trackUserInteraction } from '../../../helpers/datalayer-utils';
import '../notifications.m.css';
import { isEqual } from 'lodash';

class NotificationButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      defaultCountFlag: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.syncNotifications = this.syncNotifications.bind(this);
    this.setCount = this.setCount.bind(this);
  }

  componentDidMount() {
    this.syncNotifications();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.member, this.props.member)) {
      this.syncNotifications();
    }
  }

  componentWillUnmount() {
    clearInterval(this.syncIntervalId);
  }

  handleClick() {
    this.props.togglePopover();
    if (this.state.count) {
      this.setCount(0);
      this.props.member &&
        this.props.member.uid &&
        BQNotifications.acknowledge(this.props.member.uid);
    }
    const _datalayerFields = {
      event: 'event Notification bell icon clicked',
      label: 'notification bell icon',
      action: 'click'
    };
    trackUserInteraction(_datalayerFields);
  }

  setCount(count) {
    this.setState({ count });
  }

  syncNotifications() {
    const { member, collectionSlug, maxCount, defaultCount } = this.props;
    const { count, defaultCountFlag } = this.state;
    if (count < maxCount) {
      if (member) {
        const currentCount = defaultCountFlag ? 0 : count;
        defaultCountFlag && this.setState({ defaultCountFlag: false, count: 0 });
        BQNotifications.sync(member, collectionSlug, currentCount)
          .then(newCount => {
            newCount !== undefined && this.setCount(newCount);
          })
          .catch(error => console.error(error));
      } else {
        if (!defaultCountFlag) {
          this.setCount(defaultCount);
          this.setState({ defaultCountFlag: true });
        }
      }
    }
  }

  render() {
    const { popoverState } = this.props;
    const activeClass = popoverState ? 'active' : '';
    return (
      <div
        data-testid="notification-button"
        styleName={`button-holder ${activeClass}`}
        onClick={this.handleClick}
      >
        <NotificationsIcon color="regent-gray" fontSize="19px" />
      </div>
    );
  }
}

NotificationButton.propTypes = {
  togglePopover: func.isRequired,
  popoverState: bool.isRequired,
  member: object,
  collectionSlug: string.isRequired,
  syncItervalTime: number.isRequired,
  maxCount: number.isRequired,
  defaultCount: number
};

export default NotificationButton;
