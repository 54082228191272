import React from 'react';
import { string } from 'prop-types';

const AngleDown = ({ color = 'fourth-color', fontSize = '10px', className = '' }) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize,
      padding: '0 4px'
    }}
    className={`icon-ic_expand ${className}`}
  />
);

AngleDown.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default AngleDown;
