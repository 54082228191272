import React from 'react';
import { node, string, bool } from 'prop-types';
import DropdownArrowIcon from '../icons/dropdown-arrow-icon';
import './menu-item.m.css';

const MenuItem = ({ href, children, hideIcon, ...props }) => {
  return (
    <a href={href} className="menu-item" styleName="menu-item" {...props}>
      {children}
      {!hideIcon && <DropdownArrowIcon styleName="icon" />}
    </a>
  );
};

MenuItem.propTypes = {
  children: node.isRequired,
  href: string.isRequired,
  hideIcon: bool,
};

MenuItem.defaultProps = {
  hideIcon: false,
};

export default MenuItem;
