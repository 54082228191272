import React from 'react';
import { objectOf, object, any } from 'prop-types';
import NotificationButton from './notification-button';
import NotificationPopover from './notification-popover';
import './notifications.m.css';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverState: false
    };
    this.togglePopover = this.togglePopover.bind(this);
    this.closePopover = this.closePopover.bind(this);
  }

  togglePopover() {
    this.setState({ popoverState: !this.state.popoverState });
  }

  closePopover() {
    this.setState({ popoverState: false });
  }

  render() {
    const notificationConfig = this.props.config.features.notifications;
    const collectionSlug = (notificationConfig && notificationConfig.collection_slug) || '';
    const storyLimit = (notificationConfig && notificationConfig.story_limit) || 3;
    const syncItervalTime = (notificationConfig && notificationConfig.sync_interval_time) || 60000;
    const maxCount = (notificationConfig && notificationConfig.max_count) || 99;
    const defaultCount = (notificationConfig && notificationConfig.default_count) || 5;

    const { popoverState } = this.state;
    const { member, config } = this.props;

    return (
      <div styleName="container">
        <NotificationButton
          member={member}
          togglePopover={this.togglePopover}
          popoverState={popoverState}
          collectionSlug={collectionSlug}
          syncItervalTime={syncItervalTime}
          maxCount={maxCount}
          defaultCount={defaultCount}
        />
        {popoverState && (
          <NotificationPopover
            closePopover={this.closePopover}
            config={config}
            collectionSlug={collectionSlug}
            storyLimit={storyLimit}
          />
        )}
      </div>
    );
  }
}

Notifications.propTypes = {
  config: objectOf(any).isRequired,
  member: object
};

export default Notifications;
