import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Popup from '../../molecules/popup/popup';
import { postCall } from '../../../helpers/auth-client';
import MoengageHelper from '../../../helpers/moengage-helper';
import './contact-us.m.css';

const checkIcon = '/images/check.svg';

const options = [
  { value: 'product-related', label: 'Product related' },
  { value: 'payment-related', label: 'Payment related' },
  { value: 'access-related', label: 'Access related' },
  { value: 'corporate-or-Campus-purchase', label: 'Corporate or Campus purchase' },
  { value: 'others', label: 'Others' }
];

const customStyles = {
  option: (base, state) => {
    const newStyles = Object.assign({}, base);
    if (state.isSelected) {
      newStyles.backgroundColor = 'rgb(42, 42, 42, 0.06)';
    } else if (state.isFocused) {
      newStyles.backgroundColor = 'rgb(42, 42, 42, 0.03)';
    }
    newStyles.color = '#2a2a2a';
    return newStyles;
  },
  control: styles => {
    const newStyles = Object.assign({}, styles);
    newStyles['&:hover'] = {
      borderColor: '#d8d8d8'
    };
    newStyles.padding = 6;
    newStyles.borderColor = '#d8d8d8';
    newStyles.boxShadow = 'none';
    return newStyles;
  },
  singleValue: base => Object.assign({}, base)
};

const ThankYou = () => (
  <div styleName="thank-you">
    <div styleName="thank-you-container">
      <img src={checkIcon} alt="" />
      <h2 styleName="heading">Thank you</h2>
      <h2 styleName="sub-heading">for getting in touch!</h2>
    </div>
    <div styleName="message-container">
      <div styleName="message">
        We appreciate you contacting us about your query. Our executive will get back to you
        shortly.
      </div>
    </div>
  </div>
);

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayError: false,
      name: '',
      email: '',
      query: null,
      message: '',
      thankyou: false
    };
    this.onNameChange = this.onNameChange.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
    this.onMessageChange = this.onMessageChange.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.postMail = this.postMail.bind(this);
  }

  onNameChange(event) {
    this.setState({
      name: event.target.value
    });
  }

  onEmailChange(event) {
    this.setState({
      email: event.target.value
    });
  }

  onQueryChange(value) {
    this.setState({
      query: value
    });
  }

  onMessageChange(event) {
    this.setState({
      message: event.target.value
    });
  }

  submitHandler(event) {
    event.preventDefault();
    if (!this.state.name || !this.state.email || !this.state.query || !this.state.message) {
      this.setState({
        displayError: true
      });
      return;
    }

    const query = {
      name: this.state.name,
      email: this.state.email,
      query: this.state.query,
      message: this.state.message
    };

    this.setState({ displayError: false }, () => this.postMail('/next/feapi/post-mail', query));
    global.Moengage && MoengageHelper.supportFormSubmitted();
    global.Moengage && MoengageHelper.supportFormStatusAttributes();
  }

  postMail(url, query) {
    postCall(url, query);
    this.setState({ thankyou: true });
  }

  render() {
    return (
      <Popup isOpen onClose={this.props.onClose} theme="theme-white">
        <div styleName="form-container">
          {this.state.thankyou && <ThankYou />}
          {!this.state.thankyou && (
            <React.Fragment>
              <h2 styleName="heading">Contact Us</h2>
              <form onSubmit={this.submitHandler}>
                <div styleName="input-wrapper">
                  <div styleName="input-group name-filed">
                    <label htmlFor="c-name" styleName="label">
                      Name
                    </label>
                    <input
                      type="name"
                      name="name"
                      id="c-name"
                      styleName="input-box"
                      placeholder="John Doe"
                      value={this.state.name}
                      onChange={this.onNameChange}
                      required
                    />
                  </div>
                  <div styleName="input-group email-field">
                    <label htmlFor="c-email" styleName="label">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="c-email"
                      styleName="input-box"
                      placeholder="John@gmail.com"
                      value={this.state.email}
                      onChange={this.onEmailChange}
                      required
                    />
                  </div>
                </div>
                <div styleName="input-group">
                  <label htmlFor="c-query" styleName="label">
                    Query
                  </label>
                  <Select
                    options={options}
                    id="c-query"
                    styleName="query"
                    styles={customStyles}
                    value={this.state.query}
                    onChange={this.onQueryChange}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="c-message" styleName="label">
                    Message
                  </label>
                  <textarea
                    rows="4"
                    cols="50"
                    name="comment"
                    form="usrform"
                    required
                    styleName="message-box input-box"
                    id="c-message"
                    placeholder="Type your message here"
                    value={this.state.message}
                    onChange={this.onMessageChange}
                  />
                </div>
                <div styleName="btn-wrap">
                  <input styleName="submit-btn" type="submit" value="Submit" />
                </div>
              </form>
              {this.state.displayError && (
                <div styleName="error-msg">
                  <span styleName="error-icon" />
                  <span>All fields need to be entered</span>
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </Popup>
    );
  }
}

ContactUs.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default ContactUs;
