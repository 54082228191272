import wretch from 'wretch';
import { startApp } from '@quintype/framework/client/start';
import 'intersection-observer';

import { renderApplication, preRenderApplication } from './render';
import CUSTOM_REDUCERS from '../store/reducers';

import '../../app/assets/stylesheets/app.scss';

function enableHotReload(store) {
  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./render', () => {
      preRenderApplication(store);
      renderApplication(store);
    });
  }
}

global.wretch = wretch;

startApp(renderApplication, CUSTOM_REDUCERS, {
  enableServiceWorker: process.env.NODE_ENV === 'production',
  appVersion: require('../isomorphic/app-version'),
  preRenderApplication,
}).then(enableHotReload);
