import React from 'react';
import { connect } from 'react-redux';
import { object, arrayOf, string } from 'prop-types';
import get from 'lodash/get';
import { BreakingNewsItem } from '@quintype/components';

import { BQLink } from '../../../atoms/bqlink';
import './breaking-news-view.module.css';

class BreakingNewsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentNewsId: 0,
      opacity: 1
    };
    this.fadeOut = this.fadeOut.bind(this);
    this.changeNews = this.changeNews.bind(this);
    this.fadeIn = this.fadeIn.bind(this);
    this.breakingNewsItemWithVideoRedirect = this.breakingNewsItemWithVideoRedirect.bind(this);
  }

  componentDidMount() {
    this.timerID = setInterval(this.fadeOut, 9000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  fadeOut() {
    this.setState({ opacity: 0 }, this.changeNews());
  }

  changeNews() {
    if (!this.changeNewsTimeout) clearTimeout(this.changeNewsTimeout);
    this.changeNewsTimeout = setTimeout(
      () =>
        this.setState(
          prevState => ({
            currentNewsId:
              (prevState.currentNewsId + 1) % get(this.props, 'breakingNews', []).length
          }),
          this.fadeIn
        ),
      500
    );
  }

  fadeIn() {
    if (!this.fadeInTimeout) clearTimeout(this.fadeInTimeout);
    this.fadeInTimeout = setTimeout(() => this.setState({ opacity: 1 }), 400);
  }

  breakingNewsItemWithVideoRedirect(currentNews) {
    const linkedStorySlug = get(currentNews, ['metadata', 'linked-story-slug'], '');
    if (linkedStorySlug.endsWith('watch-bloombergquint-live-2')) {
      currentNews.metadata['linked-story-slug'] = 'videos';
    }
    return currentNews;
  }

  arrowIcon = bnSlugExists =>
    bnSlugExists ? (
      <div styleName="mouse_scroll">
        <div style={{ transform: 'rotateZ(-90deg)' }}>
          <span styleName="m_scroll_arrows unu"></span>
          <span styleName="m_scroll_arrows doi"></span>
          <span styleName="m_scroll_arrows trei"></span>
        </div>
      </div>
    ) : (
      <></>
    );

  render() {
    const pageType = this.props.pageType;
    if (
      get(this.props, 'breakingNews', []).length === 0 ||
      (pageType !== 'home-page' && pageType !== 'section-page' && pageType !== 'story-page')
    ) {
      return <div />;
    }

    const currentNews = get(this.props, ['breakingNews', `${this.state.currentNewsId}`], {});
    const bnSlugExists = get(currentNews, ['metadata', 'linked-story-slug'], null);
    const mobileClass = bnSlugExists ? 'reduce-top' : '';

    const breakingNewsStory = () => (
      <div styleName="wrapper">
        <div className="container" styleName="container">
          <div styleName="title">BREAKING NEWS</div>
          <div
            styleName={`bn-story ${mobileClass}`}
            style={{ opacity: this.state.opacity }}
            key={this.state.currentNewsId}
          >
            <div styleName="bn-headline" className="desktop-only">
              <BreakingNewsItem item={this.breakingNewsItemWithVideoRedirect(currentNews)} />
            </div>
            <div styleName="story-scroller-wrapper" className="mobile-only">
              <div styleName={`stories`}>
                <div styleName="story-headline">
                  <BreakingNewsItem item={this.breakingNewsItemWithVideoRedirect(currentNews)} />
                </div>
              </div>
            </div>
            <span styleName="gradient"></span>
            {this.arrowIcon(bnSlugExists)}
          </div>
        </div>
      </div>
    );

    return bnSlugExists ? (
      <BQLink href={`/${bnSlugExists}`}>{breakingNewsStory()}</BQLink>
    ) : (
      breakingNewsStory()
    );
  }
}

BreakingNewsView.propTypes = {
  breakingNews: arrayOf(object).isRequired,
  pageType: string
};

const mapStateToProps = state => ({
  pageType: get(state, ['qt', 'pageType'], '')
});

export default connect(mapStateToProps, {})(BreakingNewsView);
