import React from 'react';
import { string } from 'prop-types';

const UserRoundIcon = ({
  color = 'font1-color',
  fontSize = 'var(--base-large)',
  className = ''
}) => (
  <span
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_profile ${className}`}
  />
);

UserRoundIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string
};

export default UserRoundIcon;
