import React from 'react';
import { func, string } from 'prop-types';

const CloseIcon = ({ color = 'font1-color', fontSize = 'var(--base-small)', className = '', onClick=()=>null }) => (
  <i
    style={{
      color: `var(--${color})`,
      fontSize: fontSize
    }}
    className={`icon-ic_close ${className}`}
    onClick={onClick}
  />
);

CloseIcon.propTypes = {
  color: string,
  fontSize: string,
  className: string,
  onClick: func
};

export default CloseIcon;
