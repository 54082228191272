import React from 'react';
import { array } from 'prop-types';
import get from 'lodash/get';

import SubMenuBarItem from '../../atoms/sub-menu-bar-item';

import './sub-menu-bar.m.css';

const SubMenuBar = ({ subMenu }) => {
  return (
    <div styleName="sub-menu-bar">
      {subMenu &&
        subMenu.map((item, index) => {
          const icon = get(item, ['data', 'icon-code'], false);
          return (
            <React.Fragment key={index}>
              <SubMenuBarItem href={item.completeUrl}>
                {(item.completeUrl === '/businessweek' || '/best-of-bloomberg') && icon ? (
                  <img styleName={`img-item-${index}`} src={icon} alt={item.title} />
                ) : (
                  item.title
                )}
              </SubMenuBarItem>
            </React.Fragment>
          );
        })}
    </div>
  );
};

SubMenuBar.propTypes = {
  subMenu: array
};

SubMenuBar.defaultProps = {
  subMenu: []
};

export default SubMenuBar;
