import { string } from 'prop-types';
import React from 'react';

import './premium.m.css';

const Premium = ({ fontSize, name }) => (
  <div style={{ fontSize: fontSize }} styleName="premium" className="premium">
    {name || 'Premium'}
  </div>
);

Premium.propTypes = {
  fontSize: string,
  name: string,
};
Premium.defaultProps = {
  name: undefined,
};

export default Premium;
